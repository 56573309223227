import React from "react";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Sidebar from "./SideBar";
import CustomButton from "./CustomButton";
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';

import { IconButton } from "@mui/material";
import { copyToClipboard, isMobile } from "utils";
import { useSnackbar } from "contexts";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useNavigateWithClient } from "hooks";

const useStyles = makeStyles((theme) => ({
  contentRoot: {
    flex: "auto",
    marginLeft: "0px",
    marginTop: "0",
    overflowY: 'scroll',
    padding: theme.spacing(5),
  },
  whiteBox: {
    padding: theme.spacing(7, 10),
    borderRadius: '4px',
    boxShadow: '0px 0px 4px 0px #00000026',
    backgroundColor: theme.palette.shades['clr-white-900'],
    [theme.breakpoints.down(725)]: {
      padding: theme.spacing(5, 3),
    }
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  header: {
    backgroundColor: theme.palette.shades['clr-white-900'],
    marginBottom: theme.spacing(5),
    padding: theme.spacing(6, 5),
    borderRadius: '4px',
    boxShadow: '0px 0px 4px 0px #00000026',
    borderLeft: '4px solid',
    borderColor: theme.palette.primary['clr-300'],
    display: 'flex',
    gap: theme.spacing(3)
  },
  childrens: {
    display: 'flex', flexDirection: 'column', gap: theme.spacing(4)
  },
  whiteBoxHeader: {
    display: 'flex', justifyContent: 'space-between',
    marginBottom: theme.spacing(5),
  }, logo: {
    width: '150px',
    [theme.breakpoints.down(725)]: {
      width: '120px',
    }
  }, actionContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
  }, socialLinks: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  bookCallBtn: {
    textTransform: 'none',
    width: '150px',
    marginRight: '20px',
    ...theme.typography['body01-bold'],
    [theme.breakpoints.down(725)]: {
      marginLeft: '30px',
      marginRight: '10px',
    }
  }
}));

export default function AppShell({
  headerLabel = '-', children, showBackButton = false, showSidebar = true,
  showDemoAppNavbar = false, goBackUrl
}) {
  const classes = useStyles();

  const hasIframe = React.useMemo(() => window.top !== window.self, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "calc(100vh - 0px)",
        marginTop: "0px",
      }}
    >
      {
        (showSidebar) && <Sidebar hasIframe={hasIframe} />
      }

      <Box
        className={classes.contentRoot}
        width={hasIframe ? "calc(100vw - 120px)" : "calc(100vw - 237px)"}
      >
        <Navbar
          label={headerLabel}
          showBackButton={showBackButton}
          showDemoAppNavbar={showDemoAppNavbar}
          goBackUrl={goBackUrl}
        />

        <Box className={classes.childrens}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export function Navbar({ showBackButton, goBackUrl, label, showDemoAppNavbar }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const navigateWithClient = useNavigateWithClient()
  const snackbar = useSnackbar();
  const [searchParams] = useSearchParams();
  const location = useLocation()

  const b2c = searchParams.get('b2c');

  const mobile = React.useMemo(() => isMobile(), []);

  const goBack = () => {
    if (location?.state?.from?.includes('/assessment')) {
      navigateWithClient('/performance');
    } else {
      goBackUrl ? navigate(goBackUrl) : navigate(-1);
    }
  };

  const handleWhatsappClick = () => {
    window.open('https://api.whatsapp.com/send?&text=' + window.location.href);
  }

  const handleCopy = () => {
    copyToClipboard(window.location.href);
    snackbar.success('Copied');
  }

  return (
    showDemoAppNavbar

      ? <Box className={classes.header} style={{ justifyContent: 'space-between'}}>
        <img
          src="https://assets.languify.in/images/languify-logo-primary.png"
          alt="Languify"
          className={classes.logo}
        />
        {
          (!mobile) && <Box className={classes.actionContainer}>
            <Box className={classes.socialLinks}>
              <Typography variant="h6-semiBold" color='neutral.clr-800'>
                Share among your network
              </Typography>
              <IconButton onClick={handleWhatsappClick}>
                <img src="https://assets.languify.in/images/whasapp.svg"
                  alt="whatsapp" width={25}
                />
              </IconButton>
              <IconButton onClick={handleCopy}>
                <InsertLinkOutlinedIcon fontSize='medium' />
              </IconButton>
            </Box>
          </Box>
        }

        {b2c ? null : (
          <CustomButton
          variant='contained'
          size="medium"
          sx={{minWidth: 'max-content'}}
          onClick={() =>
            window.open('https://calendly.com/growth-dtc/15min?month=2023-12', '_self')
          }
          className={classes.bookCallBtn}
        >
          Book a call
        </CustomButton>
        )}
      </Box>

      : <Box className={classes.header}>
        {
          showBackButton &&
          <Box className={classes.backButton} onClick={goBack}>
            <img
              src="https://assets.languify.in/images/arrow-left-icon.png"
              alt="moveLeftIcon"
              width={36}
              height={36}
            />
          </Box>
        }
        <Typography variant="h4-medium" color='primary.main'>
          {label}
        </Typography>
      </Box>
  )
}

export function WhiteBox({ children, ...props }) {
  const classes = useStyles();

  return (
    <Box className={classes.whiteBox} {...props}>
      {children}
    </Box>
  );
}

export function WhiteBoxHeader({
  id = '', heading = '', subheading = '', sx = {}, children, ...props
}) {
  const classes = useStyles();

  return (
    <Box className={classes.whiteBoxHeader} style={sx} {...props}>
      <Box style={{ display: 'flex', flexDirection: 'column' }} id={id}>
        <Typography variant="h4-medium" color='shades.clr-black-900'>
          {heading}
        </Typography>
        <Typography variant="body01-bold" color='neutral.clr-600'>
          {subheading}
        </Typography>
      </Box>
      {children}
    </Box>
  )
}