import * as React from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import InfoIconWithTooltip, { ClickableTooltip } from 'components/InfoIconWithTooltip';
import ParameterTag from './ParameterTag';
import Chip from 'components/Chip';
import {shallow} from 'zustand/shallow';
import useStore from './store';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import { Box, Divider, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getCommentAndColor } from './helpers';
import { makeStyles } from '@mui/styles';
import { Categories, CategoriesDescription, SubCategories, SubCategoriesDescription } from './constants';
import { useCallback, useState, useEffect } from 'react';
import { round } from 'utils';
import { getBlogsByScore } from 'services/blogService';
import { useServices } from 'contexts';
import { useNavigateWithClient } from 'hooks';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: '2px solid',
  width: '100%',
  borderColor: theme.palette.primary['clr-100'],
  borderRadius: '4px',
  '&:not(:last-child)': {
    borderBottom: '2px solid',
    borderColor: theme.palette.primary['clr-100'],
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: '#1961BF', }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.shades['clr-white-900'],
  borderBottom: '1px solid',
  borderColor: theme.palette.neutral['clr-200'],
  margin: theme.spacing(0, 4, 0, 4),
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    // marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  margin: theme.spacing(2, 4, 2, 4),
}));

const useStyles = makeStyles((theme) => ({
  'blogContainer': {
    display: 'flex',
    border: '1px solid',
    borderColor: theme.palette.primary['clr-300'],
    width: 300,
    height: 102,
    borderRadius: '4px',
    marginBottom: '5px',
    cursor: 'pointer',
    overflow:'hidden',
    '&:hover': {
      boxShadow: '0px 2px 8px 0px #00000029',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  'rightSection': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mistakeCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1, 2),
    borderRadius: '4px',
    width: 'fit-content',
    minWidth: 'fit-content',
    backgroundColor: '#F4F0FF',
    ...theme.typography['body01-semiBold'],
  },
  divider: {
    borderColor: theme.palette.neutral['clr-200'],
    margin: theme.spacing(2, 0),
  },
  subCategory: {
    display: 'flex',
    alignItems: 'center',
    gap: 4
  },
  blogTitle: {
    width: '60%', backgroundColor: '#DEF7FC',
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    textAlign: 'center', padding: theme.spacing(2,3)
  }
}));

function SubFeatures({ tags }) {
  const classes = useStyles();

  const filterCategories = React.useMemo(() => Object.keys(SubCategories), []);

  return (
    tags.map((tag, index) => (

      filterCategories.includes(tag.subCategory) ?
        <Box key={index}>
          <Box className={classes.subCategory} mt={3} >
            <Typography variant='body01-bold' color='neutral.clr-700'>
              {SubCategories[tag.subCategory]}
            </Typography>
            <InfoIconWithTooltip
              placement='right'
              title={SubCategoriesDescription[tag.subCategory]}
              fontSize='small'
            />
          </Box>

          <ParameterTag tag={tag} />

          <Divider className={classes.divider} />
        </Box>
        : null

    ))
  );
}

function Blogs({ blogs = [] }) {
  const classes = useStyles();
  const navigate = useNavigateWithClient();

  const isDemoApp = useStore(state => state.isDemoApp, shallow);

  const handleClick = useCallback((blogId) => navigate(`/blog/${blogId}`), []);

  return (
    blogs.length ? <>
      <Typography variant='body01-bold' color='success.clr-700' mt={3} id='tips-for-improvement'>
        Tips for improvement ✨
      </Typography>
      <Typography variant='body01-bold' color='neutral.clr-500' my={1} >
        Read this Blog
      </Typography>
      {
        blogs.map(({ title, _id }, i) => (
          <ClickableTooltip 
            title={
              <Box style={{ display: 'flex', alignItems: 'center', gap: '10px'}}>
                <WarningAmberIcon style={{ color: '#664100'}}/>
                <Typography variant='body02-semiBold'>
                  Only available to<br/>registered students
                </Typography>
              </Box>
              } 
            placement='top' 
            { ...(!isDemoApp ? { open: false } : {}) }
          >
            <Box className={classes.blogContainer} key={i} 
              onClick={() => {
                if(!isDemoApp) handleClick(_id)
              }}
            >
              <Box className={classes.blogTitle}>
                <Typography variant='body02-semiBold'>{title}</Typography>
              </Box>
              <Box width='40%' className={classes.rightSection}>
                <img src={
                    !isDemoApp 
                    ? "https://assets.languify.in/images/blog-showcase.svg"
                    : "https://assets.languify.in/images/blog-showcase.svg" 
                  }
                  alt="Blog"
                  height={'95%'}
                />
              </Box>
            </Box>
          </ClickableTooltip>
        ))
      }
    </> : null
  )
}

export default function FeatureAccordion(
  { featureData, index, expanded = false, itemRef, onExpand = () => { } }
) {
  const service = useServices();

  const { category, tags, score } = featureData;
  const { color, bgColor, fontColor, tag } = getCommentAndColor(score);

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const parseScore = useCallback((value) => {
    const normalizedValue = round(value / 10);
    return normalizedValue < 10 ? `0${normalizedValue}/10` : `${normalizedValue}/10`;
  }, [score]);

  useEffect(() => {
    if (expanded && !blogs.length) {

      setLoading(true);
      (async () => {
        try {
          const blogFilters = {
            category: category,
            score: round(score / 10),
          }

          const { blogs } = await service.callService(
            getBlogsByScore, blogFilters
          );

          setBlogs(blogs);
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [expanded]);

  return (
    <>
      <Accordion expanded={expanded} onChange={() => onExpand(index)} >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
          <Box display='flex' justifyContent='space-between' alignItems='center' width='100%' ml={2} ref={itemRef}>
            <Box display='flex' justifyContent='center' alignItems='center'>
              <Typography variant='body01-bold' mr={1}>
                {Categories[category]}
              </Typography>
              <InfoIconWithTooltip
                placement='right'
                title={CategoriesDescription[category]}
                fontSize='small'
              />
              <Typography variant='body01-bold' ml={2} color={fontColor || color}>
                {parseScore(score)}
              </Typography>
            </Box>

            <Chip content={tag} bgColor={bgColor} color={fontColor || color} />
          </Box>
        </AccordionSummary>
        <AccordionDetails >
          <Box display='flex' flexDirection='column'>

            {(tags) && <SubFeatures tags={tags} />}

            {
              (loading) ?
                <>
                  <Skeleton width={'60%'} />
                  <Skeleton width={'40%'} />
                  <Skeleton
                    variant="rounded" height={80}
                    style={{ borderRadius: '4px', marginTop: 5 }}
                  />

                </> :
                <Blogs blogs={blogs} />
            }
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
